<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Arb',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.hello {
  background-color: #3f4643;
  border-radius: 10px;
  margin: 0 auto;
  max-width: 50vw;
  padding: .25rem 1rem;
}
h1 {
  color: rgb(57, 184, 161);
}
a {
  color: #42b983;
}
</style>
